footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

/* Stylizacja logo */
.footer__logo {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
    display: inline-block;
}

/* SVG logo */
.footer-logo {
    width: 150px;  /* Ustal rozmiar logo */
    height: auto;
    fill: var(--color-bg);  /* Kolor logo */
    transition: fill 0.3s ease;  /* Animacja zmiany koloru */
}

.footer-logo:hover {
    fill: var(--color-bg);  /* Kolor logo na hover */
}

/* Linki w stopce */
.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
    cursor: pointer;
}

.permalinks li {
    list-style: none;
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

/* ==================== Small DEVICES - Telefony ====================*/
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer__logo {
        font-size: 1.5rem;  /* Zmniejszanie rozmiaru logo na małych urządzeniach */
    }

    .footer-logo {
        width: 120px;  /* Zmniejsz rozmiar logo na małych urządzeniach */
    }
}
