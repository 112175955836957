.container {
  padding: 2rem;
}

.slider-wrapper {
  position: relative;
  max-width: 100rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  background-color: var(--color-primary);
  border-radius: 50%;
  opacity: 0.75;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  z-index: 2; /* Added z-index to ensure arrows are above the slider */
}

.slider-arrow.left {
  left: -7rem;
}

.slider-arrow.right {
  right: -7rem;
}

.slider-arrow svg {
  color: #1a1a1a;
}

.slider {
  display: flex;
  overflow-x: auto;
  box-shadow: 0 1.5rem 3rem -0.75rem hsla(0, 0%, 0%, 0.25);
  border-radius: 0.5rem;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.slider img {
  flex: 0 0 auto; /* Changed flex property to fix issues with some browsers */
  scroll-snap-align: start;
  object-fit: cover;
  pointer-events: none; /* Disable clicking and selecting images */
}

.slider-info {
  position: absolute;
  bottom: -3.8rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  width: 100%;
}

.mobile-info {
  display: none;
}

@media (max-width: 1024px) {
  .slider-arrow {
    display: none;
  }
  .mobile-info {
    display: block;
    font-size: 0.8rem;
    color: #1a1a1a;
    background-color: var(--color-primary);
    padding: 0.5rem;
    border-radius: 0.5rem;
    opacity: 0.75;
  }
}
