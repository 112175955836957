.prices__container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr;
}

.price {
    background: var(--color-bg-variant);
    border-radius: 3rem 3rem 3rem 3rem;
    border:  1px solid var(--color-primary);
    height: fit-content;
    width: 100%;
    transition: var(--transition);
    text-align: left;

}

.price:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;

}

.price__list {
    padding: 1rem;
}

.price__list li {
    gap: 1rem;
    margin-bottom: 0.8rem;
    text-align: center;
}

.price__list-icon {
    color: var(--color-primary);
    margin-top: 2px;

}

.price__list p {
    font-size: 0.8rem;

}

table {
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

caption,
th,
td {
    padding: 1rem;
}

caption {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 700;
}

th,
td {
    font-size: 0.8rem;
}

th::after {
    display: flex;
    content: "";
    height: 1px;
    background: var(--color-primary);
    width: 100%;
    
  }
  td {
    border-bottom: 1px solid var(--color-bg);
  }

/* ==================== MEDIUM DEVICES - Tablety ====================*/
@media screen and (max-width: 1024px) {
    .prices__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .price {
        height: auto;
        border-radius: 1rem 1rem 1rem 1rem;
        width: fit-content;
    }
    .price__list {
        text-align: left;
        gap: 2rem;
        padding-left: 0.3rem;

    }
    caption,
th,
td {
    padding: 0%;
}
th::after {
    display: flex;
    content: "";
    height: 1px;
    background: var(--color-primary);
    width: 50%;
    align-content: center;
  }
  td {
    border-bottom: 1px solid var(--color-bg);
  }
}
    
/* ==================== Small DEVICES - Telefony ====================*/
@media screen and (max-width: 600px) {
    .prices__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    .price {
        height: auto;
        border-radius: 1rem 1rem 1rem 1rem;
        width: fit-content;
    }
    .price__list {
        text-align: left;
        gap: 2rem;
        padding-left: 0.3rem;

    }
    caption,
th,
td {
    padding: 0%;
}
th::after {
    display: flex;
    content: "";
    height: 1px;
    background: var(--color-primary);
    width: 50%;
    align-content: center;
  }
  td {
    border-bottom: 1px solid var(--color-bg);
  }
}