nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.5rem 0.5rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    position: fixed;
    z-index: 999;
    cursor: pointer;

}

nav a {
    background: transparent;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 0.8rem;
    nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.5rem 0.5rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    position: fixed;
    z-index: 999;
    cursor: pointer;
}

nav a {
    background: transparent;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 0.8rem;
    cursor: pointer;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
    cursor: pointer;
}
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}

/* ==================== MEDIUM DEVICES - Tablety ====================*/
@media screen and (max-width: 1024px) {
    nav {
        background: rgba(0, 0, 0, 0.3);
        width: max-content;
        display: flex;
        padding: 0.5rem 0.5rem;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1rem;
        gap: 0.8rem;
        border-radius: 3rem;
        backdrop-filter: blur(15px);
        position: fixed;
        z-index: 999;
    
    }
    nav a {
        background: transparent;
        padding: 0.6rem;
        border-radius: 50%;
        display: flex;
        color: var(--color-light);
        font-size: 1.5rem;
    }
}