.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me4 {
    margin-left: 5rem;
    margin-right: 5rem;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, 
    transparent, 
    var(--color-primary), 
    transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image4 {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
    pointer-events: none; /* Disable clicking and selecting images */
    width: 100%;
    height: 100%;
    display: flex; /* Dodano, aby umożliwić lepsze skalowanie wideo */
    justify-content: center;
    align-items: center;
}

.about__me-image4 video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Dopasowanie wideo do kontenera */
    border-radius: 2rem;
    transform: rotate(0); /* Reset rotacji wideo */
}

.about__me-image4:hover {
    transform: rotate(0); /* Możesz dodać dodatkowy efekt na hover */
}

/*.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light); 
}*/
.about__content4 p {
   margin: 4rem 5rem 2.6rem;
   color: var(--color-light);
   text-align: center;
   margin-bottom: 5rem; /* Zwiększenie odstępu pod tekstem */
}

.about__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .about__buttons .btn2 {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 1.25rem 2.6rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    font-size: 1.3rem;
    text-decoration: none; /* Usuwanie podkreślenia */
    text-align: center; /* Wyśrodkowanie tekstu */
  }
  
  .about__buttons .btn2:hover {
    background: var(--color-primary);
    color: var(--color-bg);
    border-color: transparent;
  }

/* ==================== MEDIUM DEVICES - Tablety ====================*/
@media screen and (max-width: 1024px) {
.about__container {
    grid-template-columns: 1fr;
    gap: 0;
    text-align: center;
}

.about__me4 {
    width: 50%;
    margin: 2rem auto 4rem;
}

.about__content4 p {
    margin: 1rem 0 1.5rem;
}
.scroll__down2 {
    display: none; /* Ukrywa przycisk na urządzeniach mobilnych */
  }
    }
      
      @media screen and (max-width: 1024px) {
        .about__buttons {
          flex-direction: column; /* Przyciski w kolumnie na mniejszych ekranach */
          gap: 1rem;
        }
      }
      

    /*======================= Przewiń w dół =====================*/
.scroll__down2 {
    position: absolute;
    right: -1.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ==================== Small DEVICES - Telefony ====================*/
@media screen and (max-width: 600px) {
    .about__me4 {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__content {
        text-align: center;
    }
    
    .about__content4 p {
        margin: 1.5rem 0;
    }
}
.about__buttons .btn2 {
    padding: 0.9rem 2rem; /* Zmniejszony padding */
    font-size: 1rem; /* Zmniejszony rozmiar tekstu */
  }

  .about__buttons {
    gap: 0.8rem; /* Zmniejszona odległość między przyciskami */
  }