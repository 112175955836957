.eror {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: var(--color-bg);
  color: var(--color-white);
  background-image: url(../assets/bg-texture.png);
}

.logo-path {
  fill: var(--color-primary);
}

.error h1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-light);
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  gap: 1.5rem;
}

.btn2 {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 1.25rem 2.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-size: 1.3rem;
}

.btn2:hover {
  background: var(--color-primary);
  color: var(--color-bg);
  border-color: transparent;
}

@media screen and (max-width: 1024px) {
  .home h1 {
      font-size: 2rem;
  }

  .buttons {
      flex-direction: column;
      gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .home {
      padding: 0 1rem;
  }

  .home h1 {
      font-size: 1.8rem;
  }
}

.logo {
  width: 300px; /* Rozmiar logo */
  height: auto; /* Zachowaj proporcje */
  margin-bottom: 1rem; /* Odstęp od napisu */
  animation: zoomInOut 1s ease-out; /* Wywołanie animacji */
}

/* Definicja animacji */
@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Początkowy rozmiar */
  }
  50% {
    transform: scale(1.2); /* Powiększenie */
  }
  100% {
    transform: scale(1); /* Powrót do pierwotnego rozmiaru */
  }
}

.content-container {
  position: relative; /* Ustalamy pozycję relative, aby inne elementy były pozycjonowane względem tego kontenera */
}

/* Główny kontener, zapobiegający pojawieniu się paska przewijania */
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: var(--color-bg);
  color: var(--color-white);
  background-image: url(../assets/bg-texture.png);
}
