.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me3 {
    margin-left: 8rem;
    margin-right: 5rem;
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(-45deg, 
    transparent, 
    var(--color-primary), 
    transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image3 {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(-10deg);
    transition: var(--transition);
    pointer-events: none; /* Disable clicking and selecting images */
    width: 100%;
    height: 100%;
    display: flex; /* Dodano, aby umożliwić lepsze skalowanie wideo */
    justify-content: center;
    align-items: center;
}

.about__me-image3 video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Dopasowanie wideo do kontenera */
    border-radius: 2rem;
    transform: rotate(0); /* Reset rotacji wideo */
}

.about__me-image3:hover {
    transform: rotate(0); /* Możesz dodać dodatkowy efekt na hover */
}

/*.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light); 
}*/
.about__content3 p {
   margin: -2rem -6rem 2.6rem;
   color: var(--color-light);
   text-align: center;
}

/* ==================== MEDIUM DEVICES - Tablety ====================*/
@media screen and (max-width: 1024px) {
.about__container3 {
    grid-template-columns: 1fr;
    gap: 0;
    text-align: center;
}

.about__me3 {
    width: 50%;
    margin: 2rem auto 4rem;
}

.about__content3 p {
    margin: 1rem 0 1.5rem;
}
    }

/* ==================== Small DEVICES - Telefony ====================*/
@media screen and (max-width: 600px) {
    .about__me3 {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__content3 {
        text-align: center;
    }
    
    .about__content3 p {
        margin: 1.5rem 0;
    }
}