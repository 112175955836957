.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: var(--color-bg);
  color: var(--color-white);
  background-image: url(../assets/bg-texture.png);
}

.logo-path {
  fill: var(--color-primary);
}

.home h1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--color-light);
  margin-bottom: 2rem;
}

.buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center; /* Centrowanie przycisków */
}

.btn2 {
  display: flex; /* Wyrównanie treści przycisków */
  align-items: center; /* Wyśrodkowanie tekstu w pionie */
  justify-content: center; /* Wyśrodkowanie tekstu w poziomie */
  width: 200px; /* Stała szerokość przycisków */
  height: 60px; /* Stała wysokość przycisków */
  padding: 0; /* Usunięcie dodatkowych odstępów */
  margin: 0; /* Resetowanie marginesów */
  color: var(--color-primary);
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-size: 1.3rem; /* Jednolita wielkość czcionki */
  text-align: center;
  white-space: nowrap; /* Zapobiega łamaniu tekstu */
  box-sizing: border-box; /* Włączenie paddingu do wymiarów */
  line-height: 1.2; /* Resetowanie wysokości linii */
  font-family: inherit; /* Wymuszenie jednolitej czcionki */
}

.btn2:hover {
  background: var(--color-primary);
  color: var(--color-bg);
  border-color: transparent;
}

@media screen and (max-width: 1024px) {
  .home h1 {
    font-size: 2rem;
  }

  .buttons {
    flex-direction: column; /* Przyciski w kolumnie na tabletach */
    gap: 1rem;
  }

  .btn2 {
    width: 100%; /* Przyciski zajmują pełną szerokość kontenera */
  }
}

@media screen and (max-width: 600px) {
  .home {
    padding: 0 1rem;
  }

  .home h1 {
    font-size: 1.8rem;
  }
}

.logo {
  width: 300px; /* Rozmiar logo */
  height: auto; /* Zachowaj proporcje */
  margin-bottom: 1rem; /* Odstęp od napisu */
  animation: zoomInOut 1s ease-out; /* Wywołanie animacji */
}

/* Definicja animacji */
@keyframes zoomInOut {
  0% {
    transform: scale(1); /* Początkowy rozmiar */
  }
  50% {
    transform: scale(1.2); /* Powiększenie */
  }
  100% {
    transform: scale(1); /* Powrót do pierwotnego rozmiaru */
  }
}

.content-container {
  position: relative; /* Ustalamy pozycję relative, aby inne elementy były pozycjonowane względem tego kontenera */
}

.corner-image1 {
  position: fixed; /* Pozostaje na swoim miejscu, nawet podczas przewijania */
  bottom: 0; /* Ustawienie obrazu 2rem od dołu */
  right: 0; /* Ustawienie obrazu 2rem od prawej */
  z-index: 10; /* Ustawiamy z-index, aby zdjęcie było nad tłem */
  width: 50vw; /* Ustalamy szerokość na 30% szerokości ekranu */
  height: auto; /* Zachowanie proporcji obrazu */
  pointer-events: none; /* Wyłącza interakcję z obrazkiem */
  max-width: 700px; /* Ustalamy maksymalną szerokość, aby nie było za duże */
  max-height: 100%; /* Ustalamy maksymalną wysokość */
}

@media screen and (max-width: 1024px) {
  .corner-image1 {
    width: 40vw; /* Zwiększamy szerokość na tabletach */
    max-width: 400px; /* Ustawiamy maksymalną szerokość */
    max-height: 50vh; /* Ograniczamy wysokość */
  }
}

@media screen and (max-width: 600px) {
  .corner-image1 {
    width: 50vw; /* Ustalamy szerokość na 50% ekranu na telefonach */
    max-width: 300px; /* Maksymalna szerokość dla telefonów */
    max-height: 40vh; /* Ograniczamy wysokość na telefonach */
  }
}
