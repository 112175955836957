.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* =================== FORM ==================== */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
    margin-bottom: 1rem;
}

.confirmation-message {
    background: var(--color-bg-variant);
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
    text-align: center;
    margin-top: 20px;
    font-size: 13px;
    padding-block: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

/* =================== KLAUZULA RODO ==================== */
.rodo-info {
    font-size: 0.8rem; /* Mniejszy rozmiar czcionki */
    font-style: italic; /* Kursywa */
    color: var(--color-light); /* Kolor zgodny z designem */
    margin-top: 10px;
    text-align: justify; /* Opcjonalnie: lepsza czytelność */
    max-width: 80%; /* Ograniczenie szerokości dla lepszego układu */
}

/* =================== CORNER IMAGE ==================== */
.content-container {
    position: relative; /* Ustalamy pozycję relative, aby inne elementy były pozycjonowane względem tego kontenera */
}

.corner-image {
    position: absolute;
    bottom: -7rem; /* Ustaw pozycję od góry (dostosuj według swoich potrzeb) */
    right: 0px; /* Ustaw pozycję od prawej strony (dostosuj według swoich potrzeb) */
    z-index: 1; /* Ustalamy z-index, aby zdjęcie było nad tłem */
    width: 700px;
    height: auto;
    pointer-events: none; /* Disable clicking and selecting images */
}

/* ==================== MEDIUM DEVICES - TABLETY ==================== */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr; /* Jedna kolumna zamiast dwóch */
        gap: 2rem;
    }

    .rodo-info {
        font-size: 0.75rem; /* Mniejszy rozmiar czcionki na tabletach */
        max-width: 90%; /* Większa szerokość */
    }

    .corner-image {
        width: 313px;
        height: auto;
    }
}

/* ==================== SMALL DEVICES - TELEFONY ==================== */
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }

    .rodo-info {
        font-size: 0.7rem; /* Minimalna czcionka dla telefonu */
        max-width: 100%; /* Pełna szerokość na małych ekranach */
        text-align: left; /* Wyrównanie do lewej dla lepszej czytelności */
    }

    .corner-image {
        width: 280px; /* Jeszcze mniejsza wersja obrazu na telefonach */
        height: auto;
    }
}
